<template>
  <div class="account-list">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="季度收益登记" />
    <!-- 数据表格 -->
    <ml-tip class="default-tip" style="margin-bottom: 0; border: 0">
      <!-- 新增按钮 -->
      <template #title>
        <div class="tip-header">
          <span></span>
          <el-button icon="el-icon-plus" type="primary" @click="clickAdd"> 新增季度收益 </el-button>
        </div>
      </template>
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="人数"
          prop="userCount"
          min-width="10%"
        />
        <!-- <el-table-column
          show-overflow-tooltip
          align="center"
          label="合同时间（年）"
          prop="year"
          min-width="10%"
        /> -->
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="合同总金额（万元）"
          prop="contractAmount"
          min-width="12%"
        />
        <el-table-column align="center" label="年份" width="80">
          <template #default="{row}">
            {{ new Date(row.createTime).getFullYear() }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="季度"
          prop="quarter"
          min-width="10%"
        />
        <el-table-column align="center" label="收入（万元）" prop="incomeAmount" min-width="13%" />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="成本（万元）"
          prop="costAmount"
          min-width="10%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="利润（万元）"
          prop="profitAmount"
          min-width="12%"
        />

        <el-table-column align="center" label="创建时间" prop="createTime" min-width="10%" />
        <el-table-column align="center" label="操作" priop="createById" min-width="20%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickUpdate(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>

    <!-- 删除账户提示框 -->
    <ml-dialog
      ref="deleteUserDialogRef"
      :content="deleteUserData.content"
      @click-submit="submitDeleteUser"
    />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  getTabberData,
  getTreeString,
  getProjectsString,
  clearMemoryPageFn,
  setMemoryPageFn
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'QuarterlyEarning',
  setup() {
    const router = useRouter()
    const { commit, dispatch, getters } = useStore()
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取m账户列表
    const getUserTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const { data, total } = await getTabberData(dispatch, 'fetchGetQuarterlyEarnings', params)
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getUserTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 添加账户
    const clickAdd = () => {
      clearMemoryPageFn(commit, {})
      router.push({ path: '/quarterlyEarningAction' })
    }
    // 修改账户
    const clickUpdate = (index, row) => {
      setMemoryPageFn(commit, {}, tabberData)
      router.push({ path: '/quarterlyEarningAction', query: { earningId: row.earningId } })
    }
    // 删除账户
    const deleteUserDialogRef = ref()
    const deleteUserData = reactive({
      content: '确定删除该季度收益？'
    })
    const clickDelete = (index, row) => {
      deleteUserData.earningId = row.earningId
      deleteUserDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteUser = () => {
      const { earningId } = deleteUserData
      dispatch('fetchDeleteQuarterlyEarning', earningId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
          deleteUserDialogRef.value.showDialog = false
        }
      })
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      getUserTabberData()
    })
    return {
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickAdd,
      clickUpdate,
      clickDelete,
      deleteUserData,
      deleteUserDialogRef,
      submitDeleteUser,
      getTreeString,
      getProjectsString
    }
  }
}
</script>

<style lang="scss" scoped>
.account-list {
  @extend %params-global;
  .el-input__inner {
    width: 170px;
  }
}
</style>
